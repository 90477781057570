import { type CountryOptionsObject } from "../../translation-keys/country-option-keys.js";

const FormCountryOptions: CountryOptionsObject = {
  "United States": "アメリカ合衆国",
  "United Kingdom": "英国",
  "Canada": "カナダ",
  "Afghanistan": "アフガニスタン",
  "Aland Islands": "オーランド諸島",
  "Albania": "アルバニア",
  "Algeria": "アルジェリア",
  "American Samoa": "米サモア",
  "Andorra": "アンドラ",
  "Angola": "アンゴラ",
  "Anguilla": "アングィラ",
  "Antarctica": "南極大陸",
  "Antigua and Barbuda": "アンチグアバーブーダ",
  "Argentina": "アルゼンチン",
  "Armenia": "アルメニア",
  "Aruba": "アルバ",
  "Australia": "オーストラリア",
  "Austria": "オーストリア",
  "Azerbaijan": "アゼルバイジャン",
  "Bahamas": "バハマ",
  "Bahrain": "バーレーン",
  "Bangladesh": "バングラデシュ",
  "Barbados": "バルバドス",
  "Belarus": "ベラルーシ",
  "Belgium": "ベルギー",
  "Belize": "ベリーズ",
  "Benin": "ベナン",
  "Bermuda": "バミューダ",
  "Bhutan": "ブータン",
  "Bolivia, Plurinational State of": "ボリビア多民族国",
  "Bonaire, Sint Eustatius and Saba": "ボネール島・シント・ユースタティウス島・サバ島",
  "Bosnia and Herzegovina": "ボスニア・ヘルツェゴビナ",
  "Botswana": "ボツワナ",
  "Bouvet Island": "ブーヴェ島",
  "Brazil": "ブラジル",
  "British Indian Ocean Territory": "イギリス領インド洋地域",
  "Brunei Darussalam": "ブルネイ・ダルサラーム国",
  "Bulgaria": "ブルガリア",
  "Burkina Faso": "ブルキナファソ",
  "Burundi": "ブルンジ",
  "Cambodia": "カンボジア",
  "Cameroon": "カメルーン",
  "Cape Verde": "カーボベルデ",
  "Cayman Islands": "ケイマン諸島",
  "Central African Republic": "中央アフリカ共和国",
  "Chad": "チャド",
  "Chile": "チリ",
  "China": "中国",
  "Christmas Island": "クリスマス島",
  "Cocos (Keeling) Islands": "ココス（キーリング）諸島",
  "Colombia": "コロンビア",
  "Comoros": "コモロ",
  "Congo": "コンゴ",
  "Congo, Democratic Republic": "コンゴ民主共和国",
  "Cook Islands": "クック諸島",
  "Costa Rica": "コスタリカ",
  "Cote d'Ivoire": "コートジボワール",
  "Croatia": "クロアチア",
  "Cuba": "キューバ",
  "Curaçao": "キュラソー島",
  "Cyprus": "キプロス",
  "Czech Republic": "チェコ共和国",
  "Denmark": "デンマーク",
  "Djibouti": "ジブチ",
  "Dominica": "ドミニカ国",
  "Dominican Republic": "ドミニカ共和国",
  "East Timor": "東ティモール",
  "Ecuador": "エクアドル",
  "Egypt": "エジプト",
  "El Salvador": "エルサルバドル",
  "Equatorial Guinea": "赤道ギニア",
  "Eritrea": "エリトリア",
  "Estonia": "エストニア",
  "Ethiopia": "エチオピア",
  "Falkland Islands (Malvinas)": "フォークランド諸島（マルビナス）",
  "Faroe Islands": "フェロー諸島",
  "Fiji": "フィジー",
  "Finland": "フィンランド",
  "France": "フランス",
  "French Guiana": "フランス領ギアナ",
  "French Polynesia": "フランス領ポリネシア",
  "French Southern Territories": "フランス領南方・南極地域",
  "Gabon": "ガボン",
  "Gambia": "ガンビア",
  "Georgia": "ジョージア",
  "Germany": "ドイツ",
  "Ghana": "ガーナ",
  "Gibraltar": "ジブラルタル",
  "Greece": "ギリシャ",
  "Greenland": "グリーンランド",
  "Grenada": "グレナダ",
  "Guadeloupe": "グアドループ島",
  "Guam": "グアム島",
  "Guatemala": "グアテマラ",
  "Guernsey": "ガーンジー島",
  "Guinea": "ギニア",
  "Guinea-Bissau": "ギニアビサウ",
  "Guyana": "ガイアナ",
  "Haiti": "ハイチ",
  "Heard Island and McDonald Islands": "ハード島及びマクドナルド諸島",
  "Holy See (Vatican City State)": "バチカン市国",
  "Honduras": "ホンジュラス",
  "Hong Kong": "香港",
  "Hungary": "ハンガリー",
  "Iceland": "アイスランド",
  "India": "インド",
  "Indonesia": "インドネシア",
  "Iran, Islamic Republic of": "イラン・イスラム共和国",
  "Iraq": "イラク",
  "Ireland": "アイルランド共和国",
  "Isle of Man": "マン島",
  "Israel": "イスラエル",
  "Italy": "イタリア",
  "Jamaica": "ジャマイカ",
  "Japan": "日本",
  "Jersey": "ジャージー",
  "Jordan": "ヨルダン",
  "Kazakhstan": "カザフスタン",
  "Kenya": "ケニア",
  "Kiribati": "キリバス",
  "Korea, Democratic People's Rep": "朝鮮民主主義人民共和国",
  "Korea, Republic of": "韓国",
  "Kuwait": "クウェート",
  "Kyrgyzstan": "キルギスタン",
  "Lao People's Democratic Republic": "ラオス人民民主共和国",
  "Latvia": "ラトビア",
  "Lebanon": "レバノン",
  "Lesotho": "レソト",
  "Liberia": "リベリア",
  "Libyan Arab Jamahiriya": "リビア・アラブ国",
  "Liechtenstein": "リヒテンシュタイン",
  "Lithuania": "リトアニア",
  "Luxembourg": "ルクセンブルグ",
  "Macau": "マカオ",
  "Macedonia, former Yugoslav Rep": "マケドニア、旧ユーゴスラビア",
  "Madagascar": "マダガスカル",
  "Malawi": "マラウイ",
  "Malaysia": "マレーシア",
  "Maldives": "モルジブ",
  "Mali": "マリ",
  "Malta": "マルタ",
  "Marshall Islands": "マーシャル諸島",
  "Martinique": "マルティニーク島",
  "Mauritania": "モーリタニア",
  "Mauritius": "モーリシャス",
  "Mayotte": "マヨット島",
  "Mexico": "メキシコ",
  "Micronesia, Federated States of": "ミクロネシア連邦",
  "Moldova, Republic of": "モルドバ共和国",
  "Monaco": "モナコ",
  "Mongolia": "モンゴル",
  "Montenegro": "モンテネグロ",
  "Montserrat": "モントセラト",
  "Morocco": "モロッコ",
  "Mozambique": "モザンビーク",
  "Myanmar": "ミャンマー",
  "Namibia": "ナミビア",
  "Nauru": "ナウル",
  "Nepal": "ネパール",
  "Netherlands": "オランダ",
  "Netherlands Antilles": "オランダ領アンティル諸島",
  "New Caledonia": "ニューカレドニア",
  "New Zealand": "ニュージーランド",
  "Nicaragua": "ニカラグア",
  "Niger": "ニジェール",
  "Nigeria": "ナイジェリア",
  "Niue": "ニウエ",
  "Norfolk Island": "ノーフォーク島",
  "Northern Mariana Islands": "北マリアナ諸島連邦",
  "Norway": "ノルウェー",
  "Oman": "オマーン",
  "Pakistan": "パキスタン",
  "Palau": "パラオ",
  "Palestinian Territory, Occupied": "被占領パレスチナ地域",
  "Panama": "パナマ",
  "Papua New Guinea": "パプアニューギニア",
  "Paraguay": "パラグアイ",
  "Philippines": "フィリピン",
  "Pitcairn": "ピトケアン島",
  "Poland": "ポーランド",
  "Portugal": "ポルトガル",
  "Puerto Rico": "プエルトリコ",
  "Qatar": "カタール",
  "Reunion": "レユニオン",
  "Romania": "ルーマニア",
  "Russian Federation": "ロシア連邦",
  "Rwanda": "ルワンダ",
  "Saint Barthélemy": "セントバーツ島",
  "Saint Kitts and Nevis": "セントキッツ島・ネビス島",
  "Saint Lucia": "セントルシア",
  "Saint Martin (French part)": "フランス領サンマルタン島",
  "Saint Pierre and Miquelon": "サンピエール島・ミクロン島",
  "Saint Vincent and the Grenadines": "セントビンセント・グレナディーン諸島",
  "Samoa": "サモア諸島",
  "San Marino": "サンマリノ",
  "Sao Tome and Principe": "サントメ・プリンシペ",
  "Saudi Arabia": "サウジアラビア",
  "Senegal": "セネガル",
  "Serbia": "セルビア",
  "Serbia and Montenegro": "セルビア・モンテネグロ",
  "Seychelles": "セイシェル",
  "Sierra Leone": "シエラ・レオネ",
  "Singapore": "シンガポール",
  "Sint Maarten (Dutch part)": "オランダ領シント・マールテン島",
  "Slovakia": "スロバキア",
  "Slovenia": "スロベニア",
  "Solomon Islands": "ソロモン諸島",
  "Somalia": "ソマリア",
  "South Africa": "南アフリカ",
  "South Georgia/South Sandwich": "サウスジョージア／サウスサンドウィッチ諸島",
  "South Sudan": "南スーダン",
  "Spain": "スペイン",
  "Sri Lanka": "スリランカ",
  "St Helena, Ascension, Tristan": "セント・ヘレナ島、アセンション島、トリスタン諸島",
  "Sudan": "スーダン",
  "Suriname": "スリナム",
  "Svalbard and Jan Mayen": "スバールバル諸島及びヤンマイエン島",
  "Swaziland": "スワジランド",
  "Sweden": "スウェーデン",
  "Switzerland": "スイス",
  "Syrian Arab Republic": "シリアアラブ共和国",
  "Taiwan": "Taiwan",
  "Tajikistan": "タジキスタン",
  "Tanzania, United Republic of": " タンザニア連合共和国",
  "Thailand": "タイ",
  "Timor-Leste": "東ティモール",
  "Togo": "トーゴ",
  "Tokelau": "トケラウ",
  "Tonga": "トンガ",
  "Trinidad and Tobago": "トリニダード・トバゴ",
  "Tunisia": "チュニジア",
  "Turkey": "トルコ",
  "Turkmenistan": "トルクメニスタン",
  "Turks and Caicos Islands": "タークス・カイコス諸島",
  "Tuvalu": "ツバル",
  "Uganda": "ウガンダ",
  "Ukraine": "ウクライナ",
  "United Arab Emirates": "アラブ首長国連邦",
  "Uruguay": "ウルグアイ",
  "US Minor Outlying Islands": "合衆国領有小離島",
  "Uzbekistan": "ウズベキスタン",
  "Vanuatu": "バヌアツ",
  "Venezuela, Bolivarian Republic of": "ベネズエラ・ボリバル共和国",
  "Viet Nam": "ベトナム",
  "Virgin Islands (U.S.)": "米領ヴァージン諸島",
  "Virgin Islands, British": "英領ヴァージン諸島",
  "Wallis and Futuna": "ウォリス・フツナ諸島",
  "Western Sahara": "西サハラ",
  "Yemen": "イエメン",
  "Zambia": "ザンビア",
  "Zimbabwe": "ジンバブエ",
};

export default FormCountryOptions;